<template>
  <Section class="section-documents" v-if="documents.length" limiter>
    <component :is="$viewport.desktop ? 'Row' : 'Column'"
               justify="between">
      <Column :class="$viewport.desktop ? 'grid-3' : ''">
        <h2 class="section-documents-title">Документы</h2>
      </Column>
      <Column :class="$viewport.desktop ? 'grid-8' : ''"
              class="section-documents-files">
        <a
          :href="link"
          class="section-documents-file"
          v-for="(link, index) in documents"
          :key="index"
          target="_blank"
        >
          <Icon class="section-documents-file-icon"
                xlink="pdf"
                viewport="0 0 56 64"
          />
        </a>
      </Column>
    </component>
  </Section>
</template>

<script>
export default {
  name: 'DocumentsSection',
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .section-documents {
    &-title, &-files {
      padding-bottom: 1rem;
    }

    &-title {
      font-size: 40px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
    }

    &-files {
      display: flex;
      flex-direction: row;
    }

    &-file {
      margin-right: 1rem;
    }
  }
</style>
